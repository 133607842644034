.button {
  width: fit-content;
  font-size: 16px;
  cursor: pointer;
  border: unset;
  text-align: center;
  border-radius: 48px;
  transition: 0.5s all ease-in-out;
  font-family: "Satoshi";
  font-weight: 700;

  &.primary {
    color: white;
    background: #430944;
    padding: 17px 32px;
  }

  &.secondary {
    color: white;
    background: #27272a;
    padding: 11.5px 25px;
    padding: 13px 25px;
  }

  &.text {
    padding: 10px 0;
    background-color: transparent;
    font-size: 16px;
    color: white;
    font-weight: 700;
  }

  &:active {
    opacity: 0.8;
  }

  &:hover {
    transform: scale(1.05);
  }
}
