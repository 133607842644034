.download {
  background: #140f14;
  color: white;

  .download-wrapper {
    padding: 30px 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    @media (max-width: 768px) {
      padding: 50px 20px;
    }

    @media (max-width: 568px) {
      flex-direction: column;
      text-align: center;
    }

    .d-left {
      @media (max-width: 568px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;
      }

      .l-text {
        font-family: "FONTSPRING DEMO - Roc Grotesk";
        font-style: normal;
        font-weight: 700;
        font-size: 42px;
        line-height: 140%;
        margin-bottom: 37px;
        max-width: 400px;

        @media (max-width: 968px) {
          font-size: 35px;
        }

        span {
          color: #6a7280;
        }
      }

      .primary {
        display: flex;
        align-items: center;
        background: #ffffff !important;
        color: #1a011b !important;

        svg {
          margin-right: 8px;
        }
      }
    }

    .d-center {
      width: 28%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      @media (max-width: 768px) {
        display: none;
      }

      img {
        width: 100%;
        max-width: 376px;
        min-width: 210px;
        height: auto;
      }
    }

    .d-right {
      width: 12%;
      min-width: 80px;

      @media (max-width: 768px) {
        width: 20%;
      }

      @media (max-width: 568px) {
        width: unset;
        max-width: 120px;
      }

      svg {
        width: 100%;
        height: auto;
      }
    }
  }
}
