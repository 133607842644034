@font-face {
  font-family: "FONTSPRING DEMO - Roc Grotesk";
  src: url("./assets/font/Fontspring-DEMO-rocgrotesk-bold.otf") format("woff2");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "FONTSPRING DEMO - Roc Grotesk";
  src: url("./assets/font/Fontspring-DEMO-rocgrotesk-medium.otf")
    format("woff2");
  font-weight: medium;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Satoshi", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  position: relative;
  overflow: hidden;

  @media (max-width: 978px) {
    &.mobile-active {
      overflow-y: hidden;
    }
  }
}

.desktop-max-width {
  max-width: 1300px;
  margin: 0 auto;
}

.desktop-max-width-smaller {
  max-width: 1000px;
  margin: 0 auto;
}

ul {
  padding-inline-start: unset;
}

.click {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  &:active {
    opacity: 0.8;
  }
}

.no-select {
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.hide-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}
