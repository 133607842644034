.footer {
  position: relative;

  .footer-wrapper {
    padding: 0 20px;

    .f-top {
      padding: 36px 0 45px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 578px) {
        display: block;
      }

      .f-t-left {
        width: 48%;
        max-width: 433px;
        min-width: 300px;
        color: #140f14;

        @media (max-width: 578px) {
          width: 100%;
        }

        p {
          line-height: 150%;
          font-size: 18px;

          @media (max-width: 578px) {
            font-size: 16px;
          }
        }

        svg {
          margin-bottom: 10px;
        }
      }

      .f-t-right {
        font-family: "FONTSPRING DEMO - Roc Grotesk";
        font-style: normal;
        font-weight: 700;
        text-align: right;

        @media (max-width: 578px) {
          text-align: left;
          margin-top: 40px;
        }

        div {
          color: #140f14;
          margin-bottom: 25px;

          @media (max-width: 578px) {
            margin-bottom: 15px;
          }
        }

        .socials {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          gap: 16px;

          @media (max-width: 578px) {
            justify-content: flex-start;
          }
        }
      }
    }

    .f-bottom {
      text-align: center;
      margin-bottom: 20px;
      font-weight: 600;
    }
  }

  .p-vect {
    position: absolute;
    z-index: -1;
    left: 0;
    top: 10px;
    bottom: 0;

    @media (max-width: 578px) {
      left: 30px;
    }

    &.two {
      right: 0;
      left: unset;

      @media (max-width: 578px) {
        display: none;
      }
    }
  }
}
