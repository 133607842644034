.we-are-everywhere {
  background: #430944;

  .w-a-e-wrapper {
    color: white;
    padding: 135px 20px;
    text-align: center;
    background-repeat: no-repeat;
    background-image: url("../../../assets/png/map-bg.png");
    background-position: center;
    background-size: contain;

    @media (max-width: 768px) {
      padding: 70px 20px;
    }

    .w-row-one {
      font-family: "FONTSPRING DEMO - Roc Grotesk";
      font-weight: 700;
      font-size: 48px;
      line-height: 140%;
      margin-bottom: 8px;

      @media (max-width: 768px) {
        font-size: 40px;
      }
    }

    .w-row-two {
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      margin: 0 auto 24px;
      max-width: 816px;

      @media (max-width: 768px) {
        font-size: 16px;
      }
    }

    .w-row-three {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 30px;
      margin-bottom: 56px;

      @media (max-width: 568px) {
        gap: 15px;
      }

      img {
        width: 14vw;
        max-width: 76px;
        height: auto;
      }
    }

    .primary {
      background: #ffffff !important;
      color: #1a011b !important;
    }
  }
}
