.banner {
  padding-top: 125px;
  padding-bottom: 50px;

  @media (max-width: 668px) {
    padding-top: 75px;
  }

  .banner-wrapper {
    padding: 0 20px;
    text-align: center;

    .b-row-one {
      font-family: "FONTSPRING DEMO - Roc Grotesk";
      font-weight: 700;
      font-size: 70px;
      text-transform: uppercase;
      color: #231a23;
      line-height: 120%;
      margin-bottom: 16px;

      @media (max-width: 668px) {
        font-size: 50px;
      }

      span {
        color: #bb2d6b;
      }
    }

    .b-row-two {
      max-width: 766px;
      color: #231a23;
      max-width: 766px;
      margin: 0 auto;
      font-size: 20px;
    }

    .b-img {
      margin-top: 100px;
      width: 100%;
      height: auto;
      max-width: 817px;

      @media (max-width: 668px) {
        margin-top: 60px;
      }
    }

    .store-btns {
      display: flex;
      justify-content: center;
      gap: 34px;
      margin-top: 80px;

      @media (max-width: 668px) {
        gap: 10px;
        margin-top: 50px;

        img {
          max-width: 150px;
          height: auto;
        }
      }
    }
  }

  .gain-access {
    padding: 0 20px;
    margin-top: 80px;

    .g-a-inner {
      padding: 50px 30px 20px;
      background: #430944;
      border-radius: 24px;
      color: white;
      position: relative;
      overflow: hidden;

      @media (max-width: 868px) {
        padding: 30px 20px 10px;
      }

      @media (max-width: 568px) {
        padding: 20px 15px 10px;
      }

      .access-vect {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 0;
        height: 100%;
      }

      .g-a-title {
        font-family: "FONTSPRING DEMO - Roc Grotesk";
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 50px;
        position: relative;
        z-index: 1;

        @media (max-width: 868px) {
          font-size: 27px;
          margin-bottom: 35px;
        }

        @media (max-width: 568px) {
          font-size: 24px;
          margin-bottom: 29px;
        }
      }

      .g-a-items {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        position: relative;
        z-index: 1;

        .a-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 18%;
          gap: 2%;
          min-width: 221px;
          margin-bottom: 30px;

          @media (max-width: 968px) {
            min-width: 200px;
            margin-bottom: 25px;
          }

          @media (max-width: 768px) {
            min-width: 170px;
            margin-bottom: 20px;
          }

          @media (max-width: 568px) {
            min-width: 150px;
          }

          img {
            max-width: 64px;
            width: auto;

            @media (max-width: 968px) {
              max-width: 54px;
            }

            @media (max-width: 768px) {
              max-width: 44px;
            }

            @media (max-width: 568px) {
              max-width: 40px;
            }
          }

          div {
            font-family: "FONTSPRING DEMO - Roc Grotesk";
            font-weight: 700;
            font-size: 20px;
            line-height: 140%;
            max-width: 153px;

            @media (max-width: 968px) {
              font-size: 18px;
            }

            @media (max-width: 768px) {
              font-size: 16px;
            }

            @media (max-width: 568px) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
