.why-penta {
  padding-top: 87px;
  position: relative;

  @media (max-width: 668px) {
    padding-top: 67px;
  }

  .w-p-inner {
    padding: 0 20px;
    position: relative;
    z-index: 1;

    .w-p-top {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 107px;

      @media (max-width: 768px) {
        margin-bottom: 40px;
      }

      .badge {
        color: #bb2d6b;
        letter-spacing: 0.025em;
        text-transform: uppercase;
        padding: 8px 32px;
        background: #fbeef4;
        border-radius: 8px;
        width: fit-content;
        font-family: "FONTSPRING DEMO - Roc Grotesk";
        font-style: normal;
        font-weight: 500;
        margin-bottom: 16px;

        @media (max-width: 768px) {
          font-size: 14px;
          margin-bottom: 8px;
        }
      }

      .t-title {
        color: #231a23;
        font-family: "FONTSPRING DEMO - Roc Grotesk";
        font-style: normal;
        font-weight: 700;
        font-size: 64px;
        line-height: 140%;

        @media (max-width: 768px) {
          font-size: 50px;
        }
      }
    }

    .w-p-features {
      .feature {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 109px;

        &.inverse {
          flex-direction: row-reverse;
        }

        @media (max-width: 668px) {
          display: block;
        }
      }

      .img-wrapper {
        width: 48%;

        @media (max-width: 668px) {
          width: 100%;
          margin-bottom: 30px;
        }

        img {
          width: 100%;
          height: auto;

          @media (max-width: 668px) {
            max-width: 500px;
          }
        }
      }

      .f-content {
        width: 47%;

        @media (max-width: 668px) {
          width: 100%;
        }

        .c-row-one {
          display: flex;
          align-items: center;
          margin-bottom: 16px;

          span {
            margin-left: 8px;
            color: #999999;
            font-family: "FONTSPRING DEMO - Roc Grotesk";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
          }
        }

        .c-row-two {
          font-family: "FONTSPRING DEMO - Roc Grotesk";
          font-style: normal;
          font-weight: 700;
          font-size: 40px;
          line-height: 140%;
          color: #231a23;
          max-width: 535px;
          text-transform: uppercase;
          margin-bottom: 24px;

          @media (max-width: 968px) {
            font-size: 36px;
            line-height: 134%;
          }

          @media (max-width: 768px) {
            font-size: 30px;
            margin-bottom: 16px;
          }
        }

        .c-row-three {
          font-weight: 400;
          font-size: 18px;
          line-height: 150%;
          color: #4b424b;
          margin-bottom: 32px;

          @media (max-width: 768px) {
            font-size: 16px;
          }
        }

        button {
          display: flex;
          align-items: center;
          gap: 8px;
          font-family: "FONTSPRING DEMO - Roc Grotesk";
          font-style: normal;
          font-weight: 700;
          text-transform: uppercase;
        }
      }
    }
  }

  .vect3 {
    position: absolute;
    right: 0;
    top: 20%;
    z-index: 0;
  }

  .vect4 {
    position: absolute;
    left: 0;
    bottom: -100px;
    z-index: 0;
  }
}
