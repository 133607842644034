@keyframes navMotion {
  from {
    transform: translateY(40px);
    opacity: 0;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@media (max-width: 978px) {
  .nav-container {
    position: sticky;
    width: 100%;
    transition: top 0.4s ease-out;
    color: black;
    margin-bottom: -2px;
    z-index: 6;

    &.show-modal {
      height: 100vh;
      overflow-y: hidden;
      position: relative;
    }

    .wrapper {
      padding: 15px 20px;
      display: flex;
      flex-direction: row;
      max-width: 1500px;
      margin: 0 auto;
      align-items: center;
      justify-content: space-between;

      .nav-links,
      .social-links,
      .nav-btns {
        display: none;
      }

      .logo {
        svg {
          transform: translateY(2px);
        }
      }

      .hamburger-menu {
        width: 30px;
        height: 40px;
        z-index: 10;
        -webkit-tap-highlight-color: transparent;
        cursor: pointer;
        .hamburger {
          margin: 10px auto;
          position: relative;
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
          -webkit-transition: 0.5s ease-in-out;
          -moz-transition: 0.5s ease-in-out;
          -o-transition: 0.5s ease-in-out;
          transition: 0.5s ease-in-out;

          & span {
            display: block;
            position: absolute;
            height: 3px;
            width: 100%;
            background-color: #231a23;
            border-radius: 3px;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: 0.25s ease-in-out;
            -moz-transition: 0.25s ease-in-out;
            -o-transition: 0.25s ease-in-out;
            transition: 0.25s ease-in-out;

            &:nth-child(1) {
              top: 0px;
            }
            &:nth-child(2) {
              top: 10px;
            }
            &:nth-child(3) {
              top: 20px;
            }
          }

          &.open {
            margin: 0px auto;

            span {
              background-color: black;
              &:nth-child(1) {
                top: 18px;
                -webkit-transform: rotate(135deg);
                -moz-transform: rotate(135deg);
                -o-transform: rotate(135deg);
                transform: rotate(135deg);
              }

              &:nth-child(2) {
                opacity: 0;
                left: -60px;
              }

              &:nth-child(3) {
                top: 18px;
                -webkit-transform: rotate(-135deg);
                -moz-transform: rotate(-135deg);
                -o-transform: rotate(-135deg);
                transform: rotate(-135deg);
              }
            }
          }
        }
      }

      .mobile-menu {
        background: white;
        opacity: 1;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        z-index: 2;
        height: 100vh;
        overflow-y: hidden;

        .wrapper-mobile {
          position: absolute;
          top: 10%;
          width: 100%;

          &.on {
            ul {
              @for $i from 1 through 7 {
                $delay: $i * calc(1s / 14);
                li:nth-child(#{$i}) {
                  font-weight: 600;
                  animation: navMotion 0.1s + 0.1 $delay forwards ease-out;
                }
              }
            }
          }

          ul {
            list-style: none;
            padding-inline-start: 20px;
            padding-inline-end: 20px;

            li {
              opacity: 0;
              text-align: center;
              margin-bottom: 44px;

              &:nth-child(5) {
                margin-bottom: 20px;
              }
              a {
                font-size: 20px;
                color: black;
              }
              .button {
                width: 100% !important;
                max-width: 400px;
                font-size: 20px !important;

                &.secondary {
                  background: black !important;
                  color: white !important;
                }
              }
            }
          }
        }

        &.off {
          visibility: hidden;
        }
      }
    }
  }
}

@media (min-width: 978px) {
  .nav-container {
    position: sticky;
    transition: top 0.4s ease-out;
    margin: 0 auto;
    margin-bottom: -2px;
    color: #231a23;
    z-index: 13;

    .wrapper {
      padding: 21px 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 auto;
      justify-content: space-between;

      .nav-links {
        display: flex;
        list-style-type: none;
        position: relative;
        z-index: 20;

        li {
          margin: 0 20px;
          font-weight: 700;
          font-size: 18px;
          cursor: pointer;
          &:hover {
            color: #bb2d6b;
          }
          &:active {
            opacity: 0.8;
          }
        }
      }

      .nav-btns {
        display: flex;

        .primary {
          display: flex;
          align-items: center;

          span {
            margin-left: 8px;
          }
        }

        &.p23 {
          .text {
            color: black !important;
            font-weight: 500;
            font-size: 18px;
          }
        }
        .button {
          min-width: 125px;
        }
      }

      .hamburger-menu,
      .mobile-menu {
        display: none;
      }
    }
  }
}

a {
  text-decoration: none;
  color: unset;
}

.nav-container {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 20;

  &.hide {
    top: -99px !important;

    @media (max-width: 978px) {
      top: -86px !important;
    }
  }

  &.add-bg {
    background-color: #f6f5f6;
  }
}
