.landing-page {
  padding-top: 98px;
  position: relative;

  @media (max-width: 768px) {
    padding-top: 87px;
  }

  .b-vect {
    position: absolute;
    top: 0;
    left: 10vw;
    right: 10vw;
    width: 80vw;
    height: 90vh;
    z-index: -1;

    @media (max-width: 768px) {
      // opacity: 0.5;
    }
  }
}
