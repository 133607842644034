.faqs {
  padding: 104px 0 130px;

  @media (max-width: 568px) {
    padding: 74px 0 90px;
  }

  .faqs-wrapper {
    padding: 0 20px;

    .f-row-one {
      color: #231a23;
      text-align: center;
      font-family: "FONTSPRING DEMO - Roc Grotesk";
      font-weight: 700;
      font-size: 48px;
      line-height: 140%;

      @media (max-width: 768px) {
        font-size: 40px;
      }
    }

    .f-row-two {
      color: #4b424b;
      font-size: 20px;
      line-height: 150%;
      text-align: center;
      max-width: 686px;
      margin: 8px auto 94px;

      @media (max-width: 768px) {
        font-size: 18px;
        margin-bottom: 70px;
      }
    }

    .faqs-main {
      display: block;
      max-width: 791px;
      margin: 0 auto;

      .faq-item {
        margin-bottom: 20px;
        border-bottom: 1px solid #eeeeee;

        .f-i-top {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          gap: 5px;
          margin-bottom: 15px;

          .i-question {
            color: #231a23;
            font-weight: 700;
            font-size: 24px;

            @media (max-width: 768px) {
              font-size: 20px;
            }

            @media (max-width: 568px) {
              font-size: 18px;
            }

            @media (max-width: 468px) {
              font-size: 16px;
            }
          }

          svg {
            @media (max-width: 568px) {
              width: 36px;
              height: 36px;
            }
          }
        }

        .f-i-bottom {
          color: #665a66;
          padding-bottom: 30px;
          display: none;

          &.is-active {
            display: block;
          }
        }
      }
    }
  }
}
