.features {
  padding: 110px 0;

  .features-wrapper {
    padding: 47px 50px;
    background: #f6f5f6;
    width: calc(100% - 140px);
    border-radius: 32px;
    position: relative;
    min-height: 605px;

    @media (max-width: 868px) {
      padding: 47px 20px;
      width: calc(100% - 40px);
      border-radius: 0;
    }

    .f-title {
      font-family: "FONTSPRING DEMO - Roc Grotesk";
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 140%;
      text-align: center;
      max-width: 479px;
      margin: 0 auto 62px;

      @media (max-width: 668px) {
        font-size: 27px;
        margin-bottom: 40px;
      }
    }

    .f-main {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media (max-width: 668px) {
        display: block;
        margin-bottom: 30px;
      }

      .f-m-left {
        width: 43%;

        @media (max-width: 668px) {
          width: 100%;
          margin-bottom: 30px;
        }

        .ctrl-btns {
          display: flex;
          gap: 17px;
          margin-bottom: 22px;

          @media (max-width: 668px) {
            position: absolute;
            right: 20px;
          }

          svg {
            height: 50px;
            width: auto;

            &.b-disabled {
              opacity: 0.5;
              cursor: not-allowed;
            }
          }
        }

        .f-i-title {
          max-width: 458px;
          color: #231a23;
          margin: 12px 0;
          font-family: "FONTSPRING DEMO - Roc Grotesk";
          font-style: normal;
          font-weight: 700;
          font-size: 40px;

          @media (max-width: 668px) {
            margin: 0;
            font-size: 32px;
          }
        }

        .f-i-desc {
          max-width: 458px;
          font-size: 18px;
          line-height: 150%;

          @media (max-width: 668px) {
            max-width: unset;
            font-size: 16px;
          }
        }
      }

      .f-m-right {
        width: 55%;
        max-width: 507px;
        min-height: 450px;

        @media (max-width: 668px) {
          width: 100%;
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .numbers {
      display: flex;
      flex-direction: row;
      gap: 18px;
      margin-top: 15px;
      position: absolute;
      bottom: 47px;

      svg {
        height: 40px;
        width: 40px;
      }
    }
  }
}
